import React, { useEffect, useState } from 'react';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import logo from '../../assets/images/logo.png';

export default () => {
  const firstVideoURL = 'https://www.tiktok.com/@kanjimono/video/6850319370695003397';
  const firstVideo = useFetch(buildTikTokOEmbedURL(firstVideoURL));

  const secondVideoURL = 'https://www.tiktok.com/@kanjimono/video/6848823039145037062';
  const secondVideo = useFetch(buildTikTokOEmbedURL(secondVideoURL));

  const thirdVideoURL = 'https://www.tiktok.com/@kanjimono/video/6838361657467653381';
  const thirdVideo = useFetch(buildTikTokOEmbedURL(thirdVideoURL));

  return <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8">
        <div className="text-center pb-5 mb-5">
          <div className="mb-10 rounded-full mx-auto overflow-hidden bg-contain bg-center" style={{width: '100px', height: '100px', backgroundImage: `url(${logo})`}}>
          </div>
          <h1 className="text-4xl font-bold leading-none">
            Kanjimono
          </h1>
          <p className="text-md lg:text-2xl mt-6 font-light">
            I'm on a journey to learn a thousand kanji!<br/>
            Follow my TikTok and learn kanji with me! <br />
          </p>
          <p className="mt-8 md:mt-12">
            <a href="https://www.tiktok.com/@kanjimono">
              <Button size="sm">Discover more on TikTok</Button>
            </a>
          </p>
        </div>
        <div className="text-center py-5">
          <div className="md:flex shadow-lg  mx-6 md:mx-auto my-10 max-w-lg md:max-w-2xl lg:h-64">
            <div className="relative w-full md:w-1/3" style={{overflow: 'hidden'}}>
              <a href="https://www.amazon.com/Remembering-Kanji-Complete-Japanese-Characters/dp/0824835921/ref=as_li_ss_il?crid=22G7SOB1IO4CV&dchild=1&keywords=remember+the+kanji&qid=1599535999&sprefix=remember+the+kanji,aps,207&sr=8-2&linkCode=li3&tag=kanjimono-20&linkId=9505cedd819de1070e60c7a4e569259f&language=en_US" target="_blank">
                <img className="h-40 md:h-64 w-full relative block md:inline object-contain md:object-cover md:object-top rounded-lg rounded-r-none pb-5/6" alt="book thumbnail" border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0824835921&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=kanjimono-20&language=en_US" />
              </a>
              <img src="https://ir-na.amazon-adsystem.com/e/ir?t=kanjimono-20&language=en_US&l=li3&o=1&a=0824835921" width="1" height="1" border="0" alt="" style={{border:'none !important',margin:'0px !important'}} />
            </div>

            <div className="w-full md:w-2/3 px-4 py-4 bg-white rounded-lg">
              <div className="flex items-center">
                <h2 className="text-xl text-gray-800 font-medium mr-auto">
                  <a class="text-black" href="https://amzn.to/3jX1zfC">Buy Remembering the Kanji Vol. 1!</a>
                </h2>
              </div>
              <p className="text-sm text-gray-700 mt-4">
                I'm using this book to study kanji! You can buy <em>Remembering the Kanji Vol. 1</em> on Amazon using my affiliate link <a className="text-gray-800" href="https://amzn.to/3jX1zfC">here</a>. <em class="text-gray-600">(note: As an Amazon Associate I earn from qualifying purchases.)</em>
              </p>
              <div className="flex items-center justify-end mt-4 top-auto">
                <a href={'https://amzn.to/3jX1zfC'}>
                  <button className="bg-gray-200 text-blue-600 px-2 py-2 rounded-md mr-2">
                    Buy the book on Amazon
                  </button>
                </a>
              </div>
            </div>
      </div>
        </div>
        <div className="text-center py-5">
          <h2 className="text-2xl font-bold leading-none">Featured Videos</h2>
            {
              firstVideo.data &&
              <FeaturedVideoCard
                title="Learn Kanji Characters!"
                description={`
                  I create videos for each kanji character I learn.
                  Each video shows the mnemonics I use to remember each character.
                  You can learn the kanji characters with me through videos!
                `}
                data={firstVideo.data}
                linkURL={firstVideoURL}
              />
            }
            {
              secondVideo.data &&
              <FeaturedVideoCard
                title="Write Japanese Numbers!"
                description={`
                  Have you wondered how Japanese numbers are read?
                  In my videos, I show you how to write numbers in Japanese.
                `}
                data={secondVideo.data}
                linkURL={secondVideoURL}
              />
            }
            {
              thirdVideo.data &&
              <FeaturedVideoCard
                title="The Start of My Journey"
                description={`
                  My journey starts with the book "Remember the Kanji".
                  It's a pretty popular book and
                  tts method of learning the kanji helps many people memorize thousands of kanji characters!
                `}
                data={thirdVideo.data}
                linkURL={thirdVideoURL}
              />
            }
        </div>
      </div>
    </section>
  </Layout>
};

// ============================================================================
// SUB COMPONENTS
// Components that are only used in this component.
// ============================================================================

function FeaturedVideoCard(props) {
  // Data is the direct response from oembed API
  const { data, description, title, linkURL } = props;
  let thumbnailURL = (data && data['thumbnail_url']);
  thumbnailURL = thumbnailURL || 'https://ik.imagekit.io/q5edmtudmz/FB_IMG_15658659197157667_wOd8n5yFyXI.jpg';
  return <>
    <div className="md:flex shadow-lg  mx-6 md:mx-auto my-10 max-w-lg md:max-w-2xl lg:h-64">
      { thumbnailURL &&
        <div className="relative w-full md:w-1/3" style={{overflow: 'hidden'}}>
          <img className="block md:hidden h-40 w-full absolute object-cover rounded-lg rounded-r-none" style={{filter: 'blur(6px)', opacity: .5}} src={thumbnailURL} alt="" />
          <img className="h-40 md:h-64 w-full relative block md:inline object-contain md:object-cover md:object-top rounded-lg rounded-r-none pb-5/6" src={thumbnailURL} alt="video thumbnail" />
        </div>
      }
      <div className="w-full md:w-2/3 px-4 py-4 bg-white rounded-lg">
        <div className="flex items-center">
          <h2 className="text-xl text-gray-800 font-medium mr-auto">
            { title }
          </h2>
        </div>
        <p className="text-sm text-gray-700 mt-4">
          { description }
        </p>
        <div className="flex items-center justify-end mt-4 top-auto">
          <a href={linkURL}>
            <button className="bg-gray-200 text-blue-600 px-2 py-2 rounded-md mr-2">
              Learn more
            </button>
          </a>
        </div>
      </div>
    </div>
  </>
}

// ============================================================================
// HELPER FUNCTIONS
// ============================================================================

function useFetch(url) {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let controller;
    (async () => {
      try {
        setIsLoading(true);
        controller = new AbortController();
        const response = await fetch(url, {
          signal: controller.signal
        });
        const responseData = await response.json();
        setData(responseData);
        setError(undefined);
      } catch (err) {
        setError(err);
        setData(undefined);
      } finally {
        setIsLoading(false);
      }
    })();
    // Cancelling in-flight request when effect is cleaned up
    return () => controller && controller.abort();
  }, [url]);

  return {data, isLoading, error};
}

function buildTikTokOEmbedURL(videoURL) {
  return `https://www.tiktok.com/oembed?url=${videoURL}`;
}
